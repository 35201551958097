import { get, post } from '@/service/http'
import { systemUrl } from "@/util/config";


const searchQuestion = (params) => post(`${systemUrl}/api/official/search/text`, params)
const getQuesDetails = (id) => get(`${systemUrl}/api/official/search/question/${id}`)

export default {
  searchQuestion,
  getQuesDetails,
}